import { BattleParticipant, ICrew, IGroupCrews, IGroupParticipants } from '@/types/battle'

function filterAndFormatCrews(inputData: ICrew[]) {
  const filteredData: Record<string, IGroupCrews> = {}

  inputData.forEach((item) => {
    const { group } = item
    if (!item.group) return

    if (!filteredData[group]) {
      filteredData[group] = {
        name: `Group ${group}`,
        id: group,
        amountMembers: 0,
        crews: [],
      }
    }

    filteredData[group].crews.push({
      id: item.id,
      name: item.name,
      randId: item.randId,
      isPromoted: item.isPromoted,
      isGone: item.isGone,
      place: item.place,
      inWaitlist: item.inWaitlist,
      group: item.group,
      participants: item.participants,
    })

    filteredData[group].amountMembers = filteredData[group].crews.length
  })

  return Object.values(filteredData).sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
}

function filterAndFormatParticipants(inputData: BattleParticipant[]): IGroupParticipants[] {
  const filteredData: Record<string, IGroupParticipants> = {}

  inputData.forEach((item) => {
    const { group } = item
    if (!item.group) return

    if (!filteredData[group]) {
      filteredData[group] = {
        name: `Group ${group}`,
        id: group,
        amountMembers: 0,
        participants: [],
      }
    }

    filteredData[group].participants.push({
      id: item.id,
      name: item.dancerName,
      randId: item.randId,
      pointsAverage: 0,
      prelims: [],
      isPromoted: false,
      isGone: false,
      passStatus: '',
      avatar: item.avatar,
      dancerName: item.dancerName,
    })

    // eslint-disable-next-line no-plusplus
    filteredData[group].amountMembers++
  })

  return Object.values(filteredData).sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
}

export { filterAndFormatParticipants, filterAndFormatCrews }
