<template>
  <BKModal
    :is-show="isShowForm"
    content-class="modal"
    @update:isShow="$emit('update:isShowForm', $event)"
  >
    <template #title>
      <div class="modal-header">
        <h4>
          {{ crewData.name }}
        </h4>
        <span>{{ crewData.participants.length }}{{ crewSize }} </span>
      </div>
    </template>
    <div class="modal-content">
      <BattleParticipantRow
        v-for="(participant, index) in crewData.participants"
        :key="participant.id"
        :battle="battle"
        :comfirmation-dialog="confirmationDialog"
        :event="event"
        :participant="participant"
      >
        <template #actions>
          <div
            v-if="!participant.confirmed"
            class="actions"
          >
            <BattleUserAgreements
              v-if="battle.hasAgreement || battle.hasWaiver"
              :battle="battle"
              :user="participant"
              class="user-agreements"
              @update:user="updateParticipant(index, $event)"
            />
            <BKButton
              :disabled="participant.showAgreements && (!participant.agreementSigned || !participant.waiverSigned)"
              :loading="loading"
              class="action"
              color="var(--color-green)"
              height="42"
              white-text
              @click="accept(participant)"
            >
              accept
            </BKButton>
            <BKButton
              class="action"
              color="var(--rasberry-red)"
              height="42"
              white-text
              @click="confirmDecline(participant)"
            >
              decline
            </BKButton>
          </div>
          <div
            v-if="participant.confirmed"
            class="actions actions_full-width"
          >
            <BKButton
              v-if="!participant.checkedIn"
              :loading="loading"
              class="action"
              color="var(--color-green)"
              height="42"
              white-text
              @click="checkIn(participant)"
            >
              check-in
            </BKButton>
            <BKButton
              v-if="participant.checkedIn"
              :loading="loading"
              class="action"
              color="var(--rasberry-red)"
              height="42"
              white-text
              @click="confirmUnCheck(participant)"
            >
              un-check
            </BKButton>
            <BKButton
              v-if="battle.canUnregister"
              class="action"
              height="42"
              outlined
              @click="confirmUnregisterDancer(participant)"
            >
              unregister
            </BKButton>
          </div>
        </template>
      </BattleParticipantRow>
    </div>
    <template #actions>
      <BKButton
        class="action mb-2"
        white-text
        @click="isShowCreateForm = true"
      >
        edit crew
      </BKButton>
      <BKButton
        v-if="battle.canUnregister"
        :loading="loading"
        class="action unregister-btn"
        outlined
        @click="confirmUnregisterCrew"
      >
        unregister crew
      </BKButton>
    </template>
    <BattleCreateCrewForm
      :battle="battle"
      :crew="crewData"
      :event="event"
      :is-show-form.sync="isShowCreateForm"
      is-edit-mode
      @update:battle="$emit('update:battle', $event)"
      @reload:battle="$emit('reload:battle')"
      @update:crew="updateParticipants"
    />
    <BKConfirmationDialog
      :confirm-text="confirmationDialog.confirmText"
      :is-show="confirmationDialog.isShow"
      :title="confirmationDialog.title"
      @cancel="confirmationDialog.isShow = false"
      @confirm="confirmationDialog.confirm"
    >
      {{ confirmationDialog.subtitle }}
    </BKConfirmationDialog>
  </BKModal>
</template>

<script>
import BattleParticipantRow from '@/components/Battle/BattleParticipantRow.vue'
import { BKConfirmationDialog, BKModal } from '@/shared/ui/modals'
import BattleCreateCrewForm from '@/components/Battle/Info/BattleCreateCrewForm.vue'
import api from '@/api'
import battleParticipantJsonReviver from '@/services/battleParticipantJsonReviver'
import BattleUserAgreements from '@/components/Battle/BattleUserAgreements.vue'
import { eventApi } from '@/enitites/event'

export default {
  name: 'BattleEditCrewModal',
  components: {
    BattleUserAgreements,
    BKConfirmationDialog,
    BattleCreateCrewForm,
    BKModal,
    BattleParticipantRow,
  },
  props: {
    isShowForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    crew: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowCreateForm: false,
      loading: false,
      crewData: {
        id: 0,
        name: '',
        participants: [],
        order: 0,
      },
      confirmationDialog: {
        isShow: false,
        title: '',
        subtitle: '',
        confirmText: '',
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        confirm: () => {},
      },
    }
  },
  computed: {
    crewSize() {
      return this.battle.crewSize === 10000 ? '' : `/${this.battle.crewSize}`
    },
  },
  watch: {
    crew() {
      this.crewData = JSON.parse(JSON.stringify(this.crew), battleParticipantJsonReviver)
    },
  },
  methods: {
    async unregisterCrew() {
      this.loading = true
      const updatedBattle = await api.admin.unregisterCrew(this.battle.id, this.crew.id)
      this.loading = false
      if (updatedBattle) {
        this.updateBattle(updatedBattle)
        this.$emit('update:isShowForm', false)
      }
    },
    confirmUnregisterCrew() {
      this.confirmationDialog = {
        isShow: true,
        title: 'Unregister',
        subtitle: `Are you sure you want to unregister crew ${this.crewData.name}?`,
        confirmText: 'Unregister',
        confirm: () => {
          this.unregisterCrew()
          this.confirmationDialog.isShow = false
        },
      }
    },
    async accept(currentParticipant) {
      const updatedBattle = await api.admin.acceptDancer(this.battle.id, this.crew.id, currentParticipant.id)

      if (updatedBattle) {
        // eslint-disable-next-line no-param-reassign
        currentParticipant.confirmed = true
        this.updateBattle(updatedBattle)
      }
    },
    async decline(currentParticipant) {
      const updatedBattle = await api.admin.declineDancer(this.battle.id, this.crew.id, currentParticipant.id)
      if (updatedBattle) {
        // eslint-disable-next-line no-param-reassign
        currentParticipant.confirmed = false
        this.crewData.participants = this.crewData.participants.filter((item) => item.id !== currentParticipant.id)
        this.updateBattle(updatedBattle)
      }
    },
    confirmDecline(currentParticipant) {
      this.confirmationDialog = {
        isShow: true,
        title: 'Decline',
        subtitle: `Are you sure you want to decline ${currentParticipant.dancerName} from '${this.crewData.name}'?`,
        confirmText: 'Decline',
        confirm: () => {
          this.decline(currentParticipant)
          this.confirmationDialog.isShow = false
        },
      }
    },
    async checkIn(currentParticipant) {
      const updatedEvent = await eventApi.checkInUser(this.event.id, currentParticipant.id)
      if (updatedEvent) {
        // eslint-disable-next-line no-param-reassign
        currentParticipant.checkedIn = true
        this.$emit('reload:battle')
      }
    },
    async unCheck(currentParticipant) {
      const updatedEvent = await api.admin.uncheckDancer(this.event.id, currentParticipant.id)
      if (updatedEvent) {
        // eslint-disable-next-line no-param-reassign
        currentParticipant.checkedIn = false
        this.$emit('reload:battle')
      }
    },
    confirmUnCheck(currentParticipant) {
      this.confirmationDialog = {
        isShow: true,
        title: 'Un-check',
        subtitle: `Are you sure you want to un-check ${currentParticipant.dancerName}?`,
        confirmText: 'Un-check',
        confirm: () => {
          this.unCheck(currentParticipant)
          this.confirmationDialog.isShow = false
        },
      }
    },
    async unregisterDancer(currentParticipant) {
      const updatedBattle = await api.admin.unregisterDancer(this.battle.id, currentParticipant.id, this.crewData.id)
      if (updatedBattle) {
        this.crewData.participants = this.crewData.participants.filter(
          (participant) => participant.id !== currentParticipant.id
        )
        this.updateBattle(updatedBattle)
      }
    },
    confirmUnregisterDancer(currentParticipant) {
      this.confirmationDialog = {
        isShow: true,
        title: 'Unregister',
        subtitle: `Are you sure you want to unregister ${currentParticipant.dancerName} from '${this.crewData.name}'?`,
        confirmText: 'Unregister',
        confirm: () => {
          this.unregisterDancer(currentParticipant)
          this.confirmationDialog.isShow = false
        },
      }
    },
    updateParticipant(index, participant) {
      this.crewData.participants = this.crewData.participants.map((selectedParticipant, i) =>
        i === index ? participant : selectedParticipant
      )
    },
    updateParticipants(value) {
      this.$emit('update:crew', value)
    },
    updateBattle(updatedBattle) {
      this.$emit('update:battle', updatedBattle)
      this.$emit('reload:battle')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.modal-header {
  @include font-h4;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
  color: var(--color-dark-800);
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .user-agreements {
    flex: 1 0 100%;
  }

  .action {
    flex: 1 1;
    border-width: 2px;
    border-radius: var(--border-radius-rounded);
  }

  .action::v-deep .v-btn__content {
    font-weight: var(--font-weight-bold);
  }

  &_full-width {
    display: grid;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;

  .modal-actions {
    display: grid;
    margin-top: auto;

    &::before {
      height: 1px;
      margin: 0 -24px 20px;
      background-color: var(--color-grey-600);
      content: '';
    }
  }
}
</style>
