<template>
  <ModalWrapper
    :is-show="isShowDelete"
    @update:isShow="emit('update:isShowDelete', $event)"
  >
    <v-card tile>
      <div class="col-10 py-10 mx-auto">
        <v-container>
          <v-row>
            <v-card-title class="cancel__title"> Delete Event</v-card-title>
          </v-row>
          <v-card-text class="pa-1">
            Are you sure you want to delete <strong v-if="event.title">{{ event.title }}</strong>
            <strong v-else>this event</strong>?
          </v-card-text>
        </v-container>

        <v-card-actions
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
          class="d-flex"
        >
          <v-btn
            class="px-10 mx-3 my-2"
            color="#000000"
            height="48"
            outlined
            tile
            width="220"
            @click="emit('update:isShowDelete', false)"
          >
            Cancel
          </v-btn>
          <v-btn
            class="px-10 mx-3 my-2"
            color="#f21849"
            height="48"
            outlined
            tile
            width="220"
            @click="removeEvent"
          >
            Delete event
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { ModalWrapper } from '@/shared/ui/modals'
import { eventApi, EventDB } from '@/enitites/event'
import { useRouter } from 'vue-router/composables'

const router = useRouter()
const props = defineProps<{
  isShowDelete: boolean
  redirectUser?: boolean
  event: EventDB
}>()

const emit = defineEmits<{
  (e: 'update:isShowDelete', value: boolean): void
  (e: 'eventDeleted'): void
}>()

async function removeEvent() {
  const resp = await eventApi.deleteEvent(props.event.id)
  if (!resp) return
  emit('update:isShowDelete', false)
  emit('eventDeleted')

  if (props.redirectUser) {
    await router.push({ name: 'EventsPage' })
  }
}
</script>
<style lang="scss" scoped>
.cancel {
  &__title {
    text-transform: uppercase;
  }
}
</style>
