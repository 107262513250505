<template>
  <div class="information">
    <v-img
      :src="srcImgEvent"
      class="information__image d-flex align-center"
    >
      <v-btn
        v-if="!srcImgEvent"
        class="information__btn-upload"
        color="var(--color-white)"
        outlined
        tile
        @click="openFileDialog"
      >
        Upload image
        <v-icon class="ml-4">
          {{ mdiUpload }}
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        class="information__btn-edit white--text"
        color="var(--color-blue-800)"
        tile
        @click="openFileDialog"
      >
        Edit Image
        <v-icon class="ml-4">
          {{ mdiLeadPencil }}
        </v-icon>
      </v-btn>
      <!--      eslint-disable vuejs-accessibility/form-control-has-label-->
      <input
        ref="inputFileRef"
        accept="image/png, image/jpeg"
        name="eventImage"
        style="display: none"
        type="file"
        @change="handlerFileChange"
      />
      <!--      eslint-enable vuejs-accessibility/form-control-has-label-->
    </v-img>
    <p class="information__title">General information *</p>

    <BKOrganizationSelect
      :orgs="getUserDB.organizations"
      :selected-org.sync="eventData.orgId"
      class="mb-3"
    />
    <v-text-field
      v-model="eventData.title"
      autocomplete="off"
      background-color="#fff"
      class="information__field"
      hide-details="auto"
      label="Event name"
      maxlength="150"
      outlined
    />
    <GmapsPlaceInput
      v-model="location"
      class="information__location"
      placeholder="Location"
    />
    <v-text-field
      v-model="eventData.website"
      :rules="[validateUrlRule]"
      autocomplete="off"
      background-color="#fff"
      class="information__field"
      hide-details="auto"
      label="Website (optional)"
      outlined
    />
    <VueEditor
      v-model="eventData.description"
      :editor-toolbar="editorConfig"
      placeholder="Give a short description of the event."
    />
    <p class="information__title">Add Admins</p>
    <!--      eslint-disable vuejs-accessibility/aria-role-->
    <BKUserSelect
      :exclude="adminIds"
      :select-user-chip.sync="eventData.staff.admins"
      label-text="Find and add Admins"
      role="admin"
      @removeUser="removeEventRole"
      @selectUser="addEventRole"
    />

    <!--      eslint-enable vuejs-accessibility/aria-role-->
    <p class="information__title">Add Host</p>
    <!--      eslint-disable vuejs-accessibility/aria-role-->
    <BKUserSelect
      :exclude="hostIds"
      :select-user-chip.sync="eventData.staff.hosts"
      label-text="Find and add Host"
      role="host"
      @removeUser="removeEventRole"
      @selectUser="addEventRole"
    />
    <!--      eslint-enable vuejs-accessibility/aria-role-->
    <p class="information__title">Add DJs</p>
    <p class="information__title information__title--description">
      You can also add DJs to individual battles when you create the battles
    </p>
    <!--      eslint-disable vuejs-accessibility/aria-role-->
    <BKUserSelect
      :exclude="djIds"
      :select-user-chip.sync="eventData.staff.djs"
      label-text="Find and add Djs"
      role="dj"
      @removeUser="removeEventRole"
      @selectUser="addEventRole"
    />
    <!--      eslint-enable vuejs-accessibility/aria-role-->
    <div class="information__navigation navigation">
      <div class="navigation__wrap-dots mt-6">
        <span
          v-for="i in 5"
          v-show="isNew"
          :key="i"
          :class="i === currentDotNav ? 'navigation__dot--active' : ''"
          class="navigation__dot"
        />
      </div>
      <div
        v-show="error"
        class="navigation__text-error error--text text-center"
      >
        {{ error }}
      </div>
      <div class="navigation__wrap-btn mb-10">
        <div class="d-flex flex-column flex-sm-row-reverse">
          <v-btn
            :disabled="!isInfoFilledOut"
            :loading="loadingNextBtn"
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="handlerClickNext"
          >
            {{ backStep ? 'save' : 'next' }}
          </v-btn>
          <v-btn
            :disabled="!backStep"
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="emit('update:screen', 'CreateEventTotal')"
          >
            {{ backStep ? 'cancel' : 'back' }}
          </v-btn>
        </div>
        <v-btn
          v-if="!backStep"
          block
          class="navigation__btn-cancel"
          color="var(--color-blue-800)"
          height="48"
          max-width="194"
          text
          @click="store.dispatch('toggleCancelEventCreation')"
        >
          cancel
        </v-btn>
        <CreateEventCancel
          :event="eventData"
          @saveAsDraft="saveAsDraft"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { VueEditor } from 'vue2-editor'

import BKUserSelect from '@/components/BKUserSelect.vue'
import BKOrganizationSelect from '@/components/BKOrganizationSelect.vue'
import GmapsPlaceInput from '@/components/GmapsPlaceInput.vue'
import CreateEventCancel from '@/pages/CreateEventProcessingPage/ui/CreateEventCancel.vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { mdiLeadPencil, mdiUpload } from '@mdi/js'
import { eventApi, EventDB, StaffRole } from '@/enitites/event'
import { computed, inject, ref } from 'vue'
import { useImage } from '@/shared/composables/image'
import { UserDB } from '@/enitites/user'
import BKEditorConfig from '../../../common/vue2Config'
import { CreateEventFormName } from '../model/types'

const { getImageById, saveImage } = useImage()
const props = withDefaults(
  defineProps<{
    isNew: boolean
    eventData: EventDB
    backStep: boolean
    screen: CreateEventFormName
  }>(),
  {
    isNew: true,
    backStep: false,
  }
)

const emit = defineEmits<{
  (e: 'updateEvent'): void
  (e: 'update:screen', value: CreateEventFormName): void
  (e: 'saveAsDraft'): void
}>()

const getUserDB = computed(() => store.getters.getUserDB)
const isShowImg = ref(props.backStep)
const location = ref({
  address: props.eventData.locationAddress,
  lat: props.eventData.locationLat,
  lng: props.eventData.locationLng,
})
const filesImgEvent = ref<FileList | null>(null)
const srcImgEvent = ref(props.eventData.image ? getImageById(props.eventData.image, 800, 800, 'event') : '')
const loadingNextBtn = ref(false)
const currentDotNav = ref(1)
const error = ref(null)
const editorConfig = ref(BKEditorConfig)
const inputFileRef = ref<HTMLInputElement | null>(null)

function isValidUrl(str: string) {
  try {
    const url = new URL(str)
    return url.protocol === 'https:'
  } catch {
    return false
  }
}

const validateUrlRule = (value: string) => isValidUrl(value) || 'URL should be in the format https://yourwebsite.com'
const isInfoFilledOut = computed(() => !!props.eventData.title)
const adminIds = computed(() => props.eventData.staff.admins.map((user) => user.id))
const djIds = computed(() => props.eventData.staff.djs.map((user) => user.id))
const hostIds = computed(() => props.eventData.staff.hosts.map((user) => user.id))

async function setImageToEvent() {
  if (!filesImgEvent.value) return
  props.eventData.image = await saveImage(filesImgEvent.value[0])
  isShowImg.value = true
}

async function setLocationToEvent() {
  props.eventData.locationAddress = location.value.address
  props.eventData.locationLat = location.value.lat
  props.eventData.locationLng = location.value.lng
}

async function saveAsDraft() {
  if (filesImgEvent.value) await setImageToEvent()
  await setLocationToEvent()

  emit('saveAsDraft')
  loadingNextBtn.value = false
}

const injectedIsClickBtnNext = inject<{
  updateIsClickBtnNext: (value: boolean) => void
}>('isClickBtnNext')

async function handlerClickNext() {
  error.value = null
  loadingNextBtn.value = true

  if (filesImgEvent.value) await setImageToEvent()
  await setLocationToEvent()

  emit('updateEvent')
  loadingNextBtn.value = false
  injectedIsClickBtnNext?.updateIsClickBtnNext(true)

  emit('update:screen', props.backStep ? 'CreateEventTotal' : 'CreateEventDate')
}

function openFileDialog() {
  inputFileRef.value?.click()
}

function handlerFileChange(evt: Event) {
  const filesImg = (evt.target as HTMLInputElement).files
  if (!filesImg?.length) return

  const reader = new FileReader()
  reader.onload = (event) => {
    srcImgEvent.value = event.target?.result as string
    filesImgEvent.value = filesImg
  }
  reader.readAsDataURL(filesImg[0])
}

async function addEventRole(user: UserDB, role: StaffRole) {
  const resp = await eventApi.addRole(props.eventData.id, user.id, role)
  if (resp) props.eventData.staff = resp
}

async function removeEventRole(user: UserDB, role: StaffRole) {
  const resp = await eventApi.removeRole(props.eventData.id, user.id, role)
  if (resp) props.eventData.staff = resp
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';
@import '@/assets/style/navigationEventCreate';

.information {
  max-width: 400px;
  margin: 0 auto;

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    background: #6cace4;

    @media (max-width: $screen-sm) {
      height: 320px;
    }
  }

  &__btn-upload {
    margin-left: 25%;
  }

  &__btn-edit {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-weight: var(--font-weight-bold);
  }

  &__title {
    margin: 12px 0;
    color: var(--color-dark-800);

    &--description {
      color: #999;
    }
  }

  &__field {
    width: 100%;
    margin-bottom: 10px !important;
  }

  &__location {
    width: 100%;
  }
}
</style>
