<template>
  <div class="battle">
    <p class="battle__title">
      Battle
      <span class="battle__title--description">(optional)</span>
    </p>
    <div class="battle__wrap-blocks d-flex flex-wrap flex-column flex-sm-row justify-center align-center">
      <div
        v-for="battle in eventData.battles"
        :key="battle.id"
        class="battle__block d-flex flex-column justify-center align-center"
      >
        <v-btn
          class="battle__block-edit"
          color="var(--color-blue-800)"
          icon
          text
          @click="handlerClickEdit(battle.id)"
        >
          <v-icon>
            {{ mdiLeadPencil }}
          </v-icon>
        </v-btn>
        <v-btn
          class="battle__block-del"
          color="var(--color-blue-800)"
          icon
          text
          @click="confirmDeleteBattle(battle.id)"
        >
          <v-icon>
            {{ mdiTrashCanOutline }}
          </v-icon>
        </v-btn>
        <span class="battle__block-title">{{ battle.title }}</span>
        <p class="battle__block-subtitle">
          {{ getBattleCategoryName(battle.crewSize) }}
        </p>
      </div>

      <BKConfirmationDialog
        :confirm-text="confirmationDialog.confirmText"
        :is-show="confirmationDialog.isShow"
        :title="confirmationDialog.title"
        @cancel="confirmationDialog.isShow = false"
        @confirm="confirmationDialog.confirm"
      >
        {{ confirmationDialog.subtitle }}
      </BKConfirmationDialog>

      <div class="battle__block d-flex justify-center align-center">
        <v-btn
          :disabled="loadingAddBtn"
          :loading="loadingAddBtn"
          class="battle__btn-add"
          color="var(--color-blue-800)"
          outlined
          tile
          @click="addBattle"
        >
          Add Battle
          <v-icon class="ml-4">
            {{ mdiPlus }}
          </v-icon>
        </v-btn>
      </div>
    </div>
    <CreateEventAddBattle
      v-if="dialog"
      :battle-id="currentBattleId"
      :is-edit-mode="true"
      :is-show-modal.sync="dialog"
      @updateBattle="updateBattle"
    />
    <div class="battle__navigation wrap navigation">
      <div class="navigation__wrap-dots mt-6">
        <span
          v-for="i in 5"
          v-show="isNew"
          :key="i"
          :class="i === currentDotNav ? 'navigation__dot--active' : ''"
          class="navigation__dot"
        />
      </div>
      <span
        v-show="error"
        class="navigation__text-error"
      >
        {{ error }}
      </span>
      <div class="navigation__wrap-btn mb-10">
        <div class="d-flex flex-column flex-sm-row-reverse">
          <v-btn
            :loading="loadingNextBtn"
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="handlerClickNext"
          >
            {{ backStep ? 'save' : 'next' }}
          </v-btn>
          <v-btn
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="backStep ? handlerClickCancelEdit() : handlerClickBack()"
          >
            {{ backStep ? 'cancel' : 'back' }}
          </v-btn>
        </div>
        <v-btn
          v-if="!backStep"
          block
          class="navigation__btn-cancel"
          color="var(--color-blue-800)"
          height="48"
          max-width="194"
          text
          @click="store.dispatch('toggleCancelEventCreation')"
        >
          cancel
        </v-btn>
      </div>
      <CreateEventCancel
        :event="eventData"
        @saveAsDraft="$emit('saveAsDraft')"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import api from '@/api'

import CreateEventAddBattle from '@/pages/CreateEventProcessingPage/ui/CreateEventAddBattle.vue'
import CreateEventCancel from '@/pages/CreateEventProcessingPage/ui/CreateEventCancel.vue'
import { BKConfirmationDialog } from '@/shared/ui/modals'
import { mdiLeadPencil, mdiPlus, mdiTrashCanOutline } from '@mdi/js'
import { battleApi, BattleDB, useBattle } from '@/enitites/battle'
import { CreateEventFormName } from '@/pages/CreateEventProcessingPage/model/types'
import { EventDB } from '@/enitites/event'
import { getCurrentInstance, inject, ref } from 'vue'

const { getBattleCategoryName } = useBattle()
const emit = defineEmits<{
  (e: 'update:screen', value: CreateEventFormName): void
  (e: 'saveAsDraft'): void
}>()

const props = defineProps<{
  isNew: boolean
  screen: CreateEventFormName
  eventData: EventDB
  backStep: boolean
}>()

const dialog = ref(false)
const currentBattleId = ref(0)
const loadingNextBtn = ref(false)
const loadingAddBtn = ref(false)
const currentDotNav = ref(4)
const error = ref<null | string>(null)
const confirmationDialog = ref({
  isShow: false,
  title: '',
  subtitle: '',
  confirmText: '',
  confirm: () => {},
})

const injectedIsClickBtnNext = inject<{
  updateIsClickBtnNext: (value: boolean) => void
}>('isClickBtnNext')

function handlerClickNext() {
  error.value = null
  loadingNextBtn.value = true
  try {
    injectedIsClickBtnNext?.updateIsClickBtnNext(true)
    emit('update:screen', 'CreateEventTotal')
  } catch {
    error.value = 'Something went wrong'
  } finally {
    loadingNextBtn.value = false
  }
}

async function deleteBattle(battleId: number) {
  const resp = await api.battles.deleteBattle(battleId)
  if (resp) {
    props.eventData.battles = props.eventData.battles.filter((battle) => battle.id !== battleId)
  }
}

function confirmDeleteBattle(id: number) {
  confirmationDialog.value = {
    isShow: true,
    title: 'Delete',
    subtitle: 'Are you sure you want to delete this battle',
    confirmText: 'Delete',
    confirm: () => {
      deleteBattle(id)
      confirmationDialog.value.isShow = false
    },
  }
}

function handlerClickBack() {
  injectedIsClickBtnNext?.updateIsClickBtnNext(false)
  emit('update:screen', 'CreateEventTiers')
}

function handlerClickCancelEdit() {
  emit('update:screen', 'CreateEventTotal')
}

async function addBattle() {
  loadingAddBtn.value = true
  const battle = await battleApi.addBattle(props.eventData.id)
  loadingAddBtn.value = false
  const eventBattleChip = {
    id: battle.id,
    title: battle.title,
    crewSize: battle.crewSize,
    isLimitExceeded: battle.isLimitExceeded,
    isRegistered: false,
    limit: battle.limit,
    orgChip: {
      id: 0,
      name: '',
      image: '',
    },
  }
  if (battle) {
    props.eventData.battles.push(eventBattleChip)
    currentBattleId.value = battle.id
    dialog.value = true
  }
}

const instance = getCurrentInstance()

async function updateBattle(battle: BattleDB) {
  const mutatedBattle = { ...battle }
  if (mutatedBattle.description) {
    mutatedBattle.description = instance?.proxy?.$sanitize(battle.description)
  }
  const resp = await api.battles.update(mutatedBattle)
  if (resp) {
    const battleIndex = props.eventData.battles.findIndex((bat) => bat.id === mutatedBattle.id)
    props.eventData.battles[battleIndex].title = mutatedBattle.title
    props.eventData.battles[battleIndex].crewSize = mutatedBattle.crewSize
  }
}

function handlerClickEdit(battleId: number) {
  currentBattleId.value = battleId
  dialog.value = true
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/navigationEventCreate';
@import '@/assets/style/mixins';

.hidden {
  visibility: hidden;
}

.show {
  visibility: visible;
}

.wrap {
  max-width: 400px;
  margin: 0 auto;
}

.battle {
  max-width: 1500px; // вмещается 4 блока батла
  min-height: 550px;
  margin: 0 auto;
  padding-top: 120px;

  &__block {
    position: relative;
    width: 100%;
    max-width: 360px;
    height: 230px;
    margin: 5px;
    padding: 0 45px;
    background: var(--color-grey-700);

    &-title {
      @include font-h2;

      color: var(--color-dark-800);
      font-weight: var(--font-weight-bold);
      text-align: center;
      word-break: break-all;
    }

    &-subtitle {
      @include font-h5;

      color: var(--color-dark-800);
      text-align: center;
    }

    &-edit {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    &-del {
      position: absolute;
      top: 50px;
      right: 15px;
    }
  }

  &__title {
    display: block;
    width: 100%;
    max-width: 360px;
    margin: 12px auto;
    color: var(--color-dark-800);

    &--description {
      color: #999;
    }
  }

  &__btn-add {
    font-weight: bold;
    border: 2px solid;
  }
}
</style>
