<template>
  <section class="create-event">
    <div class="create-event__background" />
    <div class="create-event__wrapper">
      <div class="create-event__container">
        <h2 class="create-event__title page-title">Create Event</h2>
        <p class="create-event__description px-2">
          All fields here are required, unless otherwise noted as optional, to be filled out to move on to the next step
        </p>
        <v-progress-circular
          v-if="loading"
          class="create-event__loader"
          color="primary"
          indeterminate
          size="50"
        />
      </div>
      <transition
        :name="isClickBtnNext ? 'slide-fade' : 'slide-fade-revers'"
        appear
        mode="out-in"
      >
        <component
          :is="currentScreen"
          :back-step.sync="backStep"
          :component="currentScreen"
          :event-data.sync="eventData"
          :is-new="isNew"
          :screen="currentScreen"
          class="create-event__screen"
          @publishEvent="publishEvent"
          @saveAsDraft="saveDraftEvent"
          @updateEvent="updateEvent"
          @update:screen="currentScreen = CreateEventForms[$event]"
        />
      </transition>
      <span
        v-show="error"
        class="create-event__error"
      >
        {{ error }}
      </span>
    </div>
  </section>
</template>

<script lang="ts" setup>
import CreateEventDate from '@/pages/CreateEventProcessingPage/ui/CreateEventDate.vue'
import CreateEventTiers from '@/pages/CreateEventProcessingPage/ui/CreateEventTiers.vue'
import CreateEventBattles from '@/pages/CreateEventProcessingPage/ui/CreateEventBattles.vue'
import CreateEventTotal from '@/pages/CreateEventProcessingPage/ui/CreateEventTotal.vue'
import { eventApi, EventDB } from '@/enitites/event'
import { computed, getCurrentInstance, onMounted, provide, ref } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import { CreateEventFormName } from '@/pages/CreateEventProcessingPage/model/types'
import CreateEventInfo from './CreateEventInfo.vue'

const router = useRouter()
const route = useRoute()
const isClickBtnNext = ref(true)

function updateIsClickBtnNext(value: boolean) {
  isClickBtnNext.value = value
}

provide('isClickBtnNext', {
  updateIsClickBtnNext,
})
type CreateEventForm =
  | typeof CreateEventInfo
  | typeof CreateEventDate
  | typeof CreateEventTiers
  | typeof CreateEventBattles
  | typeof CreateEventTotal

const CreateEventForms: Record<CreateEventFormName, CreateEventForm> = {
  CreateEventInfo,
  CreateEventDate,
  CreateEventTiers,
  CreateEventBattles,
  CreateEventTotal,
}
const currentScreen = ref<CreateEventForm | undefined>()

function setCurrentScreen(value: CreateEventFormName) {
  currentScreen.value = CreateEventForms[value]
}

provide('currentScreen', {
  currentScreen,
  setCurrentScreen,
})
const eventData = ref<EventDB>()
const backStep = ref(false)
const loading = ref(true)
const error = ref(null)
const isNew = ref(true)

const getUserDB = computed(() => store.getters.getUserDB)
const instance = getCurrentInstance()

async function initEvent() {
  if (!getUserDB.value) {
    await router.push({ name: 'EventsPage' })
    return
  }

  const event = await eventApi.getEvent(Number(route.params.id))

  if (!event) {
    loading.value = false
    await router.push({ name: 'EventsPage' })
    return
  }
  eventData.value = event

  if (eventData.value.title) {
    isNew.value = false
    currentScreen.value = CreateEventTotal
    loading.value = false
  } else {
    await store.dispatch('setIsCreateEventFlow', true)
    currentScreen.value = CreateEventForms.CreateEventInfo
    loading.value = false
  }
}

async function updateEvent() {
  if (!eventData.value) return
  if (eventData.value.description) {
    eventData.value.description = instance?.proxy?.$sanitize(eventData.value.description)
  }
  await eventApi.updateEvent(eventData.value)
}

async function publishEvent() {
  if (!eventData.value) return
  await eventApi.publishEvent(eventData.value.id)
  await store.dispatch('showAlertToast', 'eventCreated')
  await router.push({ name: 'Event', params: { id: String(eventData.value.id) } })
}

async function saveDraftEvent() {
  if (!eventData.value) return
  await eventApi.updateEvent(eventData.value)
  await store.dispatch('showAlertToast', 'eventCreated')
  await router.push({ name: 'Event', params: { id: String(eventData.value.id) } })
}

onMounted(() => {
  initEvent()
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/slideFade';
@import '@/assets/style/mixins';

.create-event {
  height: 100%;
  background-color: rgb(255 255 255);

  &__background {
    height: 488px;
    background: #fff url('/img/HeroCE.jpg') 60% 95% / cover no-repeat;
  }

  &__error {
    color: red;
  }

  &__loader {
    margin-top: 40px;
    margin-left: 45%;
  }

  &__wrapper {
    width: 100%;
    min-height: 500px;
    margin: -270px 0 auto;
    padding: 0 20px;
  }

  &__container {
    max-width: 400px;
    margin: 0 auto;
  }

  &__title {
    @include font-h2;

    text-align: center;
    text-transform: uppercase;
  }

  &__description {
    @include font-body2;

    color: var(--color-dark-800);
    text-align: center;
  }
}
</style>
