<template>
  <div class="cost">
    <p class="cost__title">
      Cost
      <span class="cost__title--description">(optional)</span>
    </p>
    <p class="cost__title cost__title--description">
      You can add multiple tier costs to an event. An example is if there are different costs for spectators vs
      competitors. You can also say an event is free if there is no cost.
    </p>
    <div
      v-for="(item, index) in eventData.tiers"
      :key="index"
      class="cost__tier"
    >
      <p class="cost__title">Tier {{ index + 1 }}</p>
      <div class="cost__wrap-title-cost">
        <v-text-field
          v-model="item.title"
          autocomplete="off"
          background-color="#fff"
          class="cost__field"
          label="Title (if only one tier optional)"
          outlined
        />
        <v-text-field
          v-model.number="item.cost"
          autocomplete="off"
          background-color="#fff"
          class="cost__field cost__field-cost ml-2"
          label="Cost"
          min="0"
          outlined
          prefix="$"
          type="number"
          @input="setTierCost(item.id)"
        />
        <v-btn
          v-if="index !== 0"
          absolute
          class="cost__remove-btn"
          color="red"
          icon
          text
          @click="eventData.tiers.splice(index, 1)"
        >
          <v-icon>{{ mdiMinusCircleOutline }}</v-icon>
        </v-btn>
      </div>
      <v-textarea
        v-if="item.description"
        v-model="item.description"
        class="mb-n5"
        label="Description"
        outlined
      />
      <v-btn
        :color="item.description ? 'red' : '#1976d2'"
        class="cost__edit-desc pa-0"
        text
        @click="item.description = !item.description ? ' ' : ''"
      >
        {{ item.description ? 'Remove description' : 'Add description' }}
      </v-btn>
    </div>
    <v-btn
      v-if="isShowBtnAddTier"
      class="cost__add-tier-btn pa-0"
      color="var(--color-blue-800)"
      text
      tile
      @click="handlerClickBtnAddTier"
    >
      Add tier
      <v-icon>{{ mdiPlus }}</v-icon>
    </v-btn>
    <div class="cost__navigation navigation">
      <div class="navigation__wrap-dots mt-6">
        <span
          v-for="i in 5"
          v-show="isNew"
          :key="i"
          :class="i === currentDotNav ? 'navigation__dot--active' : ''"
          class="navigation__dot"
        />
      </div>
      <span
        v-show="error"
        class="navigation__text-error"
      >
        {{ error }}
      </span>
      <div class="navigation__wrap-btn mb-10">
        <div class="d-flex flex-column flex-sm-row-reverse">
          <v-btn
            :loading="loadingNextBtn"
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="handlerClickNext"
          >
            {{ backStep ? 'save' : 'next' }}
          </v-btn>
          <v-btn
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="backStep ? handlerClickCancelEdit() : handlerClickBack()"
          >
            {{ backStep ? 'cancel' : 'back' }}
          </v-btn>
        </div>
        <v-btn
          v-if="!backStep"
          block
          class="navigation__btn-cancel"
          color="var(--color-blue-800)"
          height="48"
          max-width="194"
          text
          @click="store.dispatch('toggleCancelEventCreation')"
        >
          cancel
        </v-btn>
      </div>
    </div>
    <CreateEventCancel
      :event="eventData"
      @saveAsDraft="$emit('saveAsDraft')"
    />
  </div>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'

import CreateEventCancel from '@/pages/CreateEventProcessingPage/ui/CreateEventCancel.vue'
import { mdiMinusCircleOutline, mdiPlus } from '@mdi/js'
import { EventDB } from '@/enitites/event'
import { computed, inject, onBeforeMount, ref } from 'vue'
import { CreateEventFormName } from '@/pages/CreateEventProcessingPage/model/types'

const emit = defineEmits<{
  (e: 'update:screen', value: CreateEventFormName): void
  (e: 'updateEvent'): void
  (e: 'saveAsDraft'): void
}>()

const props = defineProps<{
  isNew: boolean
  eventData: EventDB
  backStep: boolean
}>()

const loadingNextBtn = ref(false)
const currentDotNav = ref(3)
const error = ref<string | null>(null)

function handlerClickBtnAddTier() {
  const id = props.eventData.tiers.length + 1
  props.eventData.tiers.push({
    fees: 0,
    taxes: 0,
    ticketCost: '',
    ticketFees: '',
    ticketTaxes: '',
    ticketTotalSum: '',
    totalSum: 0,
    id,
    title: '',
    cost: 0,
    description: '',
  })
}

const isShowBtnAddTier = computed(() => props.eventData.tiers.length < 10)
const injectedIsClickBtnNext = inject<{
  updateIsClickBtnNext: (value: boolean) => void
}>('isClickBtnNext')

function handlerClickNext() {
  error.value = null
  loadingNextBtn.value = true
  emit('updateEvent')
  injectedIsClickBtnNext?.updateIsClickBtnNext(true)
  emit('update:screen', props.backStep ? 'CreateEventTotal' : 'CreateEventBattles')
  loadingNextBtn.value = false
}

function handlerClickBack() {
  injectedIsClickBtnNext?.updateIsClickBtnNext(false)
  emit('update:screen', 'CreateEventDate')
}

function handlerClickCancelEdit() {
  emit('update:screen', 'CreateEventTotal')
}

function setTierCost(tierId: number) {
  const tierItem = props.eventData.tiers.find((tier) => tier.id === tierId)
  if (!tierItem) return
  tierItem.cost = Math.abs(tierItem.cost)
  if (tierItem.cost > 0 && tierItem.cost < 1) tierItem.cost = 1
}

onBeforeMount(() => {
  if (props.eventData.tiers.length === 0) handlerClickBtnAddTier()
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/navigationEventCreate';
@import '@/assets/style/mixins';

.cost {
  max-width: 400px;
  margin: 140px auto 60px;

  &__title {
    margin: 12px 0 5px;
    color: var(--color-dark-800);

    &--description {
      margin-bottom: 25px;
      color: #999;
    }
  }

  &__wrap-title-cost {
    position: relative;
    display: flex;
  }

  &__field {
    width: 100%;
    margin-bottom: -20px !important;
  }

  &__field-cost {
    max-width: 80px;
    margin-left: 10px;
  }

  &__remove-btn {
    top: 8px;
    right: -35px;
  }

  &__edit-desc {
    margin-top: -15px;
    text-transform: none;
  }

  &__btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 58px;
    margin-bottom: 8px;
    font-weight: bold;
    background-position: center right 10px;
    border: 2px solid;
  }

  &__add-tier-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70px;
    font-weight: var(--font-weight-bold);
  }
}
</style>
