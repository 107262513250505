// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { callPrivateApi } from '@/api/apiHelper'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { initGetEventResp, initUpdateEventReq } from '@/api/adapter'
import { EventDB, EventPeriodStats, EventSearchParams, EventStaff, StaffRole } from '../model/types'

export const eventApi = {
  async getEvent(eventId: number): Promise<EventDB> {
    const data = await callPrivateApi<EventDB>('get', `/events/${eventId}`)
    return initGetEventResp(data)
  },

  async checkinCurrentUser(eventId: number): Promise<EventDB> {
    const data = await callPrivateApi<EventDB>('post', `/events/${eventId}/checkin`)
    return initGetEventResp(data)
  },

  async getEvents({
    pageNumber: p,
    pageSize: pp,
    tab: t,
    sortBy: s,
    userId: u,
    keyword: k,
    orgIds: o,
    userLat: ulat,
    userLng: ulon,
    start,
    end,
  }: EventSearchParams): Promise<EventDB[] | string> {
    const data = await callPrivateApi('get', '/events', {
      params: {
        p,
        pp,
        t,
        s,
        u,
        k,
        o,
        ulat,
        ulon,
        start,
        end,
      },
    })
    if (!Array.isArray(data)) return data
    return data.map((event) => initGetEventResp(event))
  },
  async getEventsAvailableTabs({ userId, orgId }: { userId?: number; orgId?: number }): Promise<EventPeriodStats> {
    return callPrivateApi('get', '/events/tabs', { params: { u: userId, o: orgId } })
  },
  async addEvent(): Promise<EventDB> {
    const data = await callPrivateApi('post', '/events')
    return initGetEventResp(data)
  },
  async updateEvent(event: EventDB): Promise<EventDB> {
    const data = await callPrivateApi('post', `/events/${event.id}`, initUpdateEventReq(event))
    return initGetEventResp(data)
  },

  async addRole(eventId: number, userId: number, role: StaffRole): Promise<EventStaff> {
    const data = await callPrivateApi('post', `/events/${eventId}/role`, { userId, role })
    return initGetEventResp(data)
  },

  async removeRole(eventId: number, userId: number, role: StaffRole): Promise<EventStaff> {
    const data = await callPrivateApi('delete', `/events/${eventId}/role`, { data: { userId, role } })
    return initGetEventResp(data)
  },
  async publishEvent(eventId: number) {
    return callPrivateApi('post', `/events/${eventId}/publish`)
  },
  async deleteEvent(eventId: number) {
    return callPrivateApi('post', `/events/${eventId}/delete`)
  },
  async checkInUser(eventId: number, userId: number): Promise<EventDB> {
    const data = await callPrivateApi('post', `/events/${eventId}/checkin/${userId}`)
    return initGetEventResp(data)
  },
}
