// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { callPrivateApi } from '@/api/apiHelper'
import { BattleDB } from '../model/types'

export const battleApi = {
  async addBattle(eventId: number): Promise<BattleDB> {
    return callPrivateApi('post', `/events/${eventId}/add-battle`)
  },
}
