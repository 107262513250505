<template>
  <v-row class="participant">
    <v-col cols="6">
      <router-link
        :to="{ name: 'Profile', params: { id: participant.id } }"
        class="member-link"
      >
        <v-avatar
          class="mr-1"
          size="42px"
        >
          <v-img :src="getAvatar(participant.avatar, 42)" />
        </v-avatar>
        {{ participant.dancerName }} <span v-if="participant.email"> | {{ participant.email }}</span>
      </router-link>
      <!--      <div class="mobile-info">-->
      <!--        <div v-if="!isFree" class="payment-mobile">-->
      <!--          <v-icon v-if="participant.hasTicket">-->
      <!--            mdi-credit-card-outline-->
      <!--          </v-icon>-->
      <!--          <v-icon v-else>-->
      <!--            mdi-currency-usd-->
      <!--          </v-icon>-->
      <!--        </div>-->
      <!--        <div v-if="!isFree" class="ticket-mobile">-->
      <!--          <template v-if="participant.hasTicket">-->
      <!--            <span v-for="(ticket, index) in participant.tickets" :key="`ticket-${index}`">-->
      <!--              {{ ticket.title }}:-->
      <!--              {{ toDollars(ticket.cost) }}-->
      <!--            </span>-->
      <!--          </template>-->
      <!--        </div>-->
      <!--        <div v-else>-->
      <!--          Free ticket-->
      <!--        </div>-->
      <!--      </div>-->
    </v-col>

    <!--    TODO uncomment when we start to sell tickets online-->
    <!--    <v-col v-if="isFree" class="payment" cols="2">-->
    <!--      Free-->
    <!--    </v-col>-->

    <!--    <v-col v-else class="payment" cols="2">-->
    <!--      <v-icon v-if="participant.hasTicket">-->
    <!--        mdi-credit-card-outline-->
    <!--      </v-icon>-->
    <!--      <v-icon v-else>-->
    <!--        mdi-currency-usd-->
    <!--      </v-icon>-->
    <!--    </v-col>-->

    <!--    <v-col v-if="isFree" class="ticket" cols="3">-->
    <!--      Free ticket-->
    <!--    </v-col>-->

    <!--    <v-col v-else class="ticket" cols="3">-->
    <!--      <template v-if="participant.hasTicket">-->
    <!--        <span v-for="(ticket, index) in participant.tickets" :key="`ticket-${index}`">-->
    <!--          {{ ticket.title }}: {{ toDollars(ticket.cost) }}-->
    <!--        </span>-->
    <!--      </template>-->
    <!--    </v-col>-->

    <v-col
      class="status"
      cols="6"
    >
      <div
        v-if="participant.checkedIn"
        class="checked-label"
      >
        <v-icon>{{ mdiCheck }}</v-icon>
        Checked-in
      </div>
      <v-btn
        v-if="!participant.checkedIn"
        :loading="loading"
        class="ml-auto white--text font-weight-bold"
        color="var(--color-green)"
        height="32"
        tile
        @click="checkIn(participant.id)"
      >
        Check-In
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { toDollars } from '@/services/currency'
import Images from '@/mixins/image'
import { mdiCheck } from '@mdi/js'
import { eventApi } from '@/enitites/event'

export default {
  name: 'CheckInParticipantsRow',
  mixins: [Images],
  props: {
    participant: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    isFree: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mdiCheck,
      loading: false,
    }
  },
  methods: {
    toDollars,
    async checkIn(participantId) {
      this.loading = true
      const updatedEvent = await eventApi.checkInUser(this.event.id, participantId)
      if (updatedEvent) this.$emit('update:event', updatedEvent)

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.payment {
  text-align: center;

  & .v-icon {
    color: var(--color-dark-900);
  }
}

.participant {
  display: flex;
  align-items: center;
  padding: 7px 0;

  .member-link {
    color: var(--color-dark-800) !important;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-600);
  }

  .payment,
  .ticket {
    @media (max-width: $screen-md) {
      display: none;
    }
  }

  .status {
    text-align: end;
  }
}

.checked-label {
  margin-left: auto;
  color: var(--color-green);

  .v-icon {
    color: var(--color-green);
  }
}

.mobile-info {
  display: none;
  margin-top: 12px;

  @media (max-width: $screen-md) {
    display: flex;
  }

  .payment-mobile {
    margin-right: 13px;

    & .v-icon {
      color: var(--color-black);
    }
  }
}
</style>
