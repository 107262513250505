<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { mdiArrowLeft, mdiEye, mdiEyeOff } from '@mdi/js'
import { getAuth, signInWithEmailAndPassword, updatePassword } from 'firebase/auth'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'

const currentPassword = ref<string>('')
const newPassword = ref<string>('')
const confirmNewPassword = ref<string>('')
const emailUser = ref<string | null>(null)
const isShowSectionPassword = ref<boolean>(false)
const showPassword = ref<boolean>(false)
const loading = ref<boolean>(false)
const error = ref<string | null>(null)

async function updatePasswordHandler() {
  if (!emailUser.value || !currentPassword.value || !getAuth() || !newPassword.value) {
    error.value = 'Something went wrong'
    return
  }
  await signInWithEmailAndPassword(getAuth(), emailUser.value, currentPassword.value)
    .then(() => updatePassword(getAuth().currentUser!, newPassword.value))
    .then(() => {
      currentPassword.value = ''
      newPassword.value = ''
      confirmNewPassword.value = ''
      store.dispatch('showAlertToast', 'changesSaved')
    })
    .catch((err) => {
      error.value = err.message
    })
}

const handlerClickSave = async () => {
  error.value = null

  if (!currentPassword.value || !newPassword.value || !confirmNewPassword.value) {
    error.value = 'All fields are required'
    return
  }

  if (newPassword.value !== confirmNewPassword.value) {
    error.value = 'Passwords must be equal'
    return
  }

  loading.value = true
  await updatePasswordHandler()
  loading.value = false
}

onMounted(() => {
  const { currentUser } = getAuth()
  if (currentUser) {
    emailUser.value = currentUser.providerData[0].email
    isShowSectionPassword.value = currentUser.providerData[0].providerId === 'password'
  }
})
</script>

<template>
  <div class="d-flex flex-column justify-space-between h-auto w100">
    <div>
      <v-row
        v-if="isShowSectionPassword"
        class="password"
        justify="start"
        no-gutters
      >
        <v-col
          class="mb-4"
          cols="12"
        >
          <button
            class="back-button"
            type="button"
            @click="store.commit('TOGGLE_MENU_MOBILE')"
          >
            <v-icon v-show="$vuetify.breakpoint.xsOnly">
              {{ mdiArrowLeft }}
            </v-icon>
            Password
          </button>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.trim="currentPassword"
            :append-icon="showPassword ? mdiEye : mdiEyeOff"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="off"
            background-color="#fff"
            dense
            label="Old Password"
            outlined
            required
            @click:append="showPassword = !showPassword"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.trim="newPassword"
            :append-icon="showPassword ? mdiEye : mdiEyeOff"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="off"
            background-color="#fff"
            dense
            label="New Password"
            outlined
            required
            @click:append="showPassword = !showPassword"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.trim="confirmNewPassword"
            :append-icon="showPassword ? mdiEye : mdiEyeOff"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="off"
            background-color="#fff"
            dense
            label="Confirm New Password"
            outlined
            required
            @click:append="showPassword = !showPassword"
          />
        </v-col>
        <v-col
          v-if="error"
          cols="12"
        >
          <p class="red--text text-center">
            {{ error }}
          </p>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="password"
        justify="start"
        no-gutters
      >
        <v-col
          class="mb-3"
          cols="12"
        >
          <h2 class="password__title">Password change is not possible for your method of authentication</h2>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-end">
      <v-btn
        :loading="loading"
        class="password__btn-save mt-8"
        color="var(--color-blue-800)"
        height="43"
        outlined
        tile
        @click="handlerClickSave"
      >
        save
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.spa {
  height: 250px;
}

.password {
  .back-button {
    @include font-h3;

    color: var(--color-dark-800);
    font-weight: var(--font-weight-medium);
  }

  &__btn-save {
    width: 180px;
    font-weight: bold;
    border: 2px solid;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin: 8px 0;
    }
  }
}
</style>
