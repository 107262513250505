<template>
  <div>
    <transition
      :name="isClickBtnNext ? 'slide-fade' : 'slide-fade-revers'"
      appear
      mode="out-in"
    >
      <keep-alive>
        <component
          :is="questionsToForms[currentScreen]"
          :component="questionsToForms[currentScreen]"
          :current-step="currentScreenIndex"
          :error="error"
          :loading="isLoading"
          :steps="availableForms.length"
          :user="updatedUser"
          @goBack="backForm"
          @submitForm="submitForm"
        />
      </keep-alive>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { useCloned } from '@vueuse/core'
import { userApi, UserDB } from '@/enitites/user'
import { useRoute, useRouter } from 'vue-router/composables'
import OnboardingFullNameForm from './OnboardingFullNameForm.vue'
import OnboardingUserNameForm from './OnboardingUserNameForm.vue'
import OnboardingBirthdayForm from './OnboardingBirthdayForm.vue'
import OnboardingLocationForm from './OnboardingLocationForm.vue'
import OnboardingAvatarForm from './OnboardingAvatarForm.vue'
import OnboardingCardForm from './OnboardingCardForm.vue'
import { FormsName } from '../types'

const props = defineProps<{
  addToExisting?: boolean
}>()

const isClickBtnNext = ref(true)
const isLoading = ref(false)
const error = ref('')
const currentScreenIndex = ref(0)
const getUserDB = computed(() => store.getters.getUserDB)
const updatedUser = ref(useCloned(getUserDB.value).cloned)
const router = useRouter()
const route = useRoute()

const availableForms: FormsName[] = ['fullName', 'dancerName', 'birthday', 'location', 'avatar', 'card']

const questionsToForms = {
  fullName: OnboardingFullNameForm,
  dancerName: OnboardingUserNameForm,
  birthday: OnboardingBirthdayForm,
  location: OnboardingLocationForm,
  avatar: OnboardingAvatarForm,
  card: OnboardingCardForm,
}

const currentScreen = computed(() => availableForms[currentScreenIndex.value])
const isLastScreen = computed(() => currentScreenIndex.value === availableForms.length - 1)

function nextForm() {
  error.value = ''
  isClickBtnNext.value = true
  currentScreenIndex.value += 1
}

async function updateUser() {
  isLoading.value = true
  const data = await userApi.updateUser(updatedUser.value)
  isLoading.value = false
  if (!data) {
    error.value = 'Something went wrong'
    return
  }
  await store.dispatch('setUserDB', updatedUser.value)
  if (
    typeof route.query.redirect === 'string' &&
    route.query.redirect !== '/signup' &&
    route.query.redirect !== '/login'
  ) {
    await router.push(route.query.redirect)
  } else {
    await router.push({ name: 'Home' })
  }
}

async function addOtherProfile() {
  isLoading.value = true
  const data = await userApi.addOtherProfile(updatedUser.value)
  isLoading.value = false
  if (!data) {
    error.value = 'Something went wrong'
    return
  }
  localStorage.setItem('currentProfileId', data.id)
  await store.dispatch('setUserDB', data)
  await router.push({ name: 'Profile', params: { id: data.id } })
}

function submitForm(value: UserDB) {
  updatedUser.value = value
  if (!isLastScreen.value) {
    nextForm()
    return
  }

  if (props.addToExisting) {
    addOtherProfile()
    return
  }
  updateUser()
}

function backForm() {
  error.value = ''
  isClickBtnNext.value = false
  currentScreenIndex.value -= 1
}

onMounted(() => {
  updatedUser.value = store.getters.getUserDB
  updatedUser.value.card = ''
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/slideFade';
</style>
