import { getAuth } from 'firebase/auth'
import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginForm from '@/components/LoginForm.vue'
import { SignupPage } from '@/pages/SignUpPage'
import ResetPassword from '@/components/ResetPassword.vue'
import MagicLink from '@/components/MagicLink.vue'
import ErrorPage from '@/components/ErrorPage.vue'
import PatreonRoutes from '@/modules/Patreon/router'
import OrganizationsRoutes from '@/modules/Organizations/router'
import FAQRoutes from '@/modules/FAQ/router'
import { ProfileSetting } from '@/pages/ProfileSetting'
import { CreateOtherProfilePage } from '@/pages/CreateOtherProfilePage'
import { ReleaseProfile } from '@/pages/ReleaseProfile'
import { ProfilePage } from '@/pages/ProfilePage'
import { PolicyPrivacyPage } from '@/pages/PolicyPrivacyPage'
import { TermsConditionsPage } from '@/pages/TermsConditionsPage'
import { EventsPage } from '@/pages/EventsPage'
import { EventPage } from '@/pages/EventPage'
import { EventsGridPage } from '@/pages/EventsGridPage'
import { EventsCalendarPage } from '@/pages/EventsCalendarPage'
import { EventsMapPage } from '@/pages/EventsMapPage'
import { CreateEventPage } from '@/pages/CreateEventPage'
import { CreateEventProcessingPage } from '@/pages/CreateEventProcessingPage'
import store from '../store'
import Home from '../pages/HomePage.vue'
import Sparring from '../pages/SparringPage/SparringPage.vue'
import Battle from '../pages/BattlePage.vue'
import BattleBrackets from '../pages/BattleBrackets.vue'
import Prelims from '../pages/Prelims.vue'

import Members from '../pages/MembersPage.vue'
import CheckInParticipants from '../pages/CheckInParticipants.vue'

const auth = getAuth()
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/event/:id/checkin',
      name: 'Checkin',
      component: CheckInParticipants,
      meta: { requiresAuth: true },
    },
    {
      path: '/events',
      name: 'EventsPage',
      component: EventsPage,
      children: [
        {
          path: 'grid',
          name: 'EventsGrid',
          component: EventsGridPage,
        },
        {
          path: 'map',
          name: 'EventsMap',
          component: EventsMapPage,
        },
        {
          path: 'calendar',
          name: 'EventsCalendar',
          component: EventsCalendarPage,
        },
      ],
    },
    {
      path: '/event/:id',
      name: 'Event',
      component: EventPage,
    },
    {
      path: '/battle/:id',
      name: 'Battle',
      component: Battle,
    },
    {
      path: '/battle/:id/brackets',
      name: 'Brackets',
      component: BattleBrackets,
    },
    {
      path: '/battle/:id/prelims',
      name: 'Prelims',
      component: Prelims,
      children: [
        {
          path: 'groups',
          name: 'CreatingPrelimsGroups',
          component: () => import('@/pages/CreatingPrelimsGroups.vue'),
          meta: { title: 'Creating Groups' },
        },
        {
          path: 'group/:groupId',
          name: 'Group',
          component: () => import('@/pages/PrelimsGroupInfo.vue'),
          meta: { title: 'Group info' },
        },
      ],
    },
    {
      path: '/sparring/:id',
      name: 'Sparring',
      component: Sparring,
    },
    {
      path: '/terms',
      name: 'Terms',
      component: TermsConditionsPage,
    },
    {
      path: '/policy',
      name: 'Policy',
      component: PolicyPrivacyPage,
    },
    {
      path: '/create-event',
      name: 'CreateEvent',
      component: CreateEventPage,
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next) => {
        if (from.name === 'CreateEventProcessing') {
          window.history.back()
          return
        }

        next()
      },
    },
    {
      path: '/create-event/:id',
      name: 'CreateEventProcessing',
      component: CreateEventProcessingPage,
      meta: { requiresAuth: true },
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginForm,
    },
    {
      path: '/signup',
      name: 'Signup',
      component: SignupPage,
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '/magic-link',
      name: 'MagicLink',
      component: MagicLink,
    },
    {
      path: '/members',
      name: 'Members',
      component: Members,
    },
    {
      path: '/404',
      name: 'ErrorPage',
      component: ErrorPage,
    },
    {
      path: '*',
      redirect: { name: 'ErrorPage' },
    },
    {
      path: '/profile-setting',
      name: 'ProfileSetting',
      component: ProfileSetting,
      meta: { requiresAuth: true },
    },
    {
      path: '/create-other-profile',
      name: 'CreateOtherProfilePage',
      component: CreateOtherProfilePage,
    },
    {
      path: '/release-profile/:id',
      name: 'ReleaseProfile',
      component: ReleaseProfile,
    },
    {
      path: '/profile/:id',
      name: 'Profile',
      component: ProfilePage,
    },
    ...PatreonRoutes,
    ...OrganizationsRoutes,
    ...FAQRoutes,
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    // eslint-disable-next-line consistent-return
    if (to.hash) return

    return { x: 0, y: 0 }
  },
})
/* eslint-disable */

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const currentUser = auth.currentUser
  if (to.name !== 'Login' && requiresAuth && !currentUser) {
    next({ name: 'Login', query: { redirect: from.path } })
  } else if (to.name !== 'Signup' && store.getters.getUserDB && !store.getters.getUserDB.dancerName) {
    next({ name: 'Signup', query: { incomplete: true, redirect: from.path } })
  } else if (to.name !== 'ErrorPage' && store.getters.getIsNotFound) {
    store.dispatch('setIsNotFound', false)
    next()
  } else {
    next()
  }
})

export default router
