<script lang="ts" setup>
import { computed } from 'vue'
import { mdiArrowLeft } from '@mdi/js'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { UserDB } from '@/enitites/user'

const props = defineProps<{
  loading?: boolean
  user: UserDB
}>()

const emit = defineEmits<{
  (e: 'update:user', value: UserDB): void
  (e: 'save'): void
}>()

const getUser = computed<UserDB>({
  get: () => props.user,
  set: (value) => emit('update:user', value),
})
</script>

<template>
  <div class="d-flex flex-column justify-space-between h-auto">
    <div>
      <v-row
        class="privacy"
        justify="start"
        no-gutters
      >
        <v-col
          class="mb-4"
          cols="12"
        >
          <button
            class="back-button"
            type="button"
            @click="store.commit('TOGGLE_MENU_MOBILE')"
          >
            <v-icon v-show="$vuetify.breakpoint.xsOnly">
              {{ mdiArrowLeft }}
            </v-icon>
            Stats
          </button>
        </v-col>
        <v-col cols="12">
          <div class="privacy__line-setting d-flex align-center justify-start py-2">
            <i class="mr-2 privacy__icon mr-4 icon-Calendar" />
            <span class="privacy__subtitle mr-auto"> Events Attended </span>
            <span class="privacy__text-switch mr-3"> Visible to public </span>
            <v-switch
              v-model="getUser.privacy.eventsAttended"
              class="mb-n2"
              color="var(--color-blue-800)"
              dense
              inset
            />
          </div>
        </v-col>
        <v-col cols="12">
          <div class="privacy__line-setting d-flex align-center justify-start py-2">
            <i class="privacy__icon mr-4 icon-RIbbon2" />
            <span class="privacy__subtitle mr-auto"> Battles Entered </span>
            <span class="privacy__text-switch mr-3"> Visible to public </span>
            <v-switch
              v-model="getUser.privacy.battlesEntered"
              class="mb-n2"
              color="var(--color-blue-800)"
              dense
              inset
            />
          </div>
        </v-col>
        <v-col cols="12">
          <div class="privacy__line-setting d-flex align-center justify-start py-2">
            <i class="privacy__icon mr-4 icon-Trophy" />
            <span class="privacy__subtitle mr-auto"> Battles Won </span>
            <span class="privacy__text-switch mr-3"> Visible to public </span>
            <v-switch
              v-model="getUser.privacy.battlesWon"
              class="mb-n2"
              color="var(--color-blue-800)"
              dense
              inset
            />
          </div>
        </v-col>
        <v-col cols="12">
          <div class="privacy__line-setting d-flex align-center justify-start py-2">
            <i class="privacy__icon mr-4 icon-Vinyl" />
            <span class="privacy__subtitle mr-auto"> Events DJ’d </span>
            <span class="privacy__text-switch mr-3"> Visible to public </span>
            <v-switch
              v-model="getUser.privacy.eventsDj"
              class="mb-n2"
              color="var(--color-blue-800)"
              dense
              inset
            />
          </div>
        </v-col>
        <v-col cols="12">
          <div class="privacy__line-setting d-flex align-center justify-start py-2">
            <i class="privacy__icon mr-4 icon-Judge" />
            <span class="privacy__subtitle mr-auto"> Battles Judged </span>
            <span class="privacy__text-switch mr-3"> Visible to public </span>
            <v-switch
              v-model="getUser.privacy.battlesJudged"
              class="mb-n2"
              color="var(--color-blue-800)"
              dense
              inset
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-end">
      <v-btn
        :loading="loading"
        class="privacy__btn-save mt-8"
        color="var(--color-blue-800)"
        height="43"
        outlined
        tile
        @click="emit('save')"
      >
        save
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';
@import '~@/assets/fonts/style.css';

.privacy {
  .back-button {
    color: var(--color-dark-800);
    font-weight: var(--font-weight-medium);

    @include font-h3;
  }

  &__icon {
    color: var(--color-gold-900);
    font-size: 28px;
  }

  &__line-setting {
    border-bottom: 1px solid #ddd;
  }

  &__subtitle {
    color: var(--color-dark-800);
  }

  &__text-switch {
    @include font-body2;

    color: #999;
  }

  &__btn-save {
    width: 180px;
    font-weight: var(--font-weight-bold);
    border: 2px solid;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin: 8px 0;
    }
  }
}
</style>
