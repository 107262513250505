<script lang="ts" setup>
import { mdiArrowLeft, mdiDotsHorizontal } from '@mdi/js'
import { computed } from 'vue'
import BKButton from '@/components/AppShared/BKButton.vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { useImage } from '@/shared/composables/image'
import { userApi, UserDB } from '@/enitites/user'

const { getImageById } = useImage()

interface IProps {
  loading: boolean
  user: UserDB
}

defineProps<IProps>()

async function setMainProfile(id: number) {
  const { data } = await userApi.setProfileAsMain(id)
  if (!data) return
  localStorage.removeItem('currentProfileId')
  await store.commit('SET_USER_DB', data)
}

const getUserDB = computed(() => store.getters.getUserDB)
</script>

<template>
  <div class="d-flex flex-column justify-space-between h-auto w100">
    <div>
      <v-row
        class="other-profiles"
        justify="start"
        no-gutters
      >
        <v-col
          class="mb-10"
          cols="12"
        >
          <button
            class="back-button"
            type="button"
            @click="store.commit('TOGGLE_MENU_MOBILE')"
          >
            <v-icon v-show="$vuetify.breakpoint.xsOnly">
              {{ mdiArrowLeft }}
            </v-icon>
            Other profiles
          </button>
        </v-col>
        <v-col
          v-if="getUserDB.profiles && getUserDB.profiles.length"
          cols="12"
        >
          <v-list class="mb-10 py-0">
            <v-list-item
              v-for="profile in getUserDB.profiles"
              :key="profile.id"
              class="py-4 px-3"
            >
              <v-list-item-avatar
                class="my-0 mr-2"
                size="48"
              >
                <img
                  :alt="profile.dancerName"
                  :src="getImageById(profile.avatar, 100, 100, 'avatar')"
                />
              </v-list-item-avatar>
              <v-list-item-content class="pa-0">
                <v-list-item-title>
                  {{ profile.dancerName }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="profile.isMain">
                  <span class="profile-type">Main profile</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="!profile.isMain">
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>
                        {{ mdiDotsHorizontal }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      class="w100"
                      tag="button"
                      @click="setMainProfile(profile.id)"
                    >
                      Set as main
                    </v-list-item>
                    <v-list-item
                      :to="{ name: 'ReleaseProfile', params: { id: profile.id } }"
                      class="w100 link"
                      tag="button"
                    >
                      Release profile
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col
          v-else
          cols="12"
        >
          <p class="text-center mb-10">Create and manage your other profiles.</p>
        </v-col>
        <v-col cols="12">
          <BKButton
            :loading="loading"
            color="var(--color-blue-800)"
            height="52"
            white-text
            @click="$route.name === 'CreateOtherProfilePage' ? '' : $router.push({ name: 'CreateOtherProfilePage' })"
          >
            Create profile
          </BKButton>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.other-profiles {
  .back-button {
    @include font-h3;

    color: var(--color-dark-800);
    font-weight: var(--font-weight-medium);
  }
}

.link {
  color: var(--color-dark-800);
}

.v-list {
  border-radius: 6px;
}

.profile-type {
  @include font-caption1;

  color: var(--color-grey-300) !important;
}
</style>
