<template>
  <ModalWrapper
    :is-show="isShowCancelEventCreation"
    @update:isShow="store.dispatch('toggleCancelEventCreation')"
  >
    <v-card tile>
      <div class="col-8 py-10 mx-auto">
        <v-container>
          <v-row>
            <v-card-title class="cancel__title"> Cancel Event</v-card-title>
          </v-row>
          <v-card-text class="pa-1"> You can delete the event or save it as a draft and work on it later.</v-card-text>
        </v-container>

        <v-card-actions
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
          class="d-flex"
        >
          <v-btn
            class="px-10 mx-3 my-2"
            color="#f21849"
            height="48"
            outlined
            tile
            @click="removeEvent"
          >
            Delete event
          </v-btn>
          <v-btn
            class="px-10 mx-3 my-2"
            color="#000000"
            height="48"
            outlined
            tile
            @click="saveAsDraftEvent"
          >
            Save as draft
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { ModalWrapper } from '@/shared/ui/modals'
import { eventApi, EventDB } from '@/enitites/event'
import { computed } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import { useRouter } from 'vue-router/composables'

const router = useRouter()
const emit = defineEmits<{
  (e: 'saveAsDraft'): void
}>()
const props = defineProps<{
  event: EventDB
}>()

const isShowCancelEventCreation = computed(() => store.state.isShowCancelEventCreation)

async function removeEvent() {
  const resp = await eventApi.deleteEvent(props.event.id)
  if (resp) {
    await store.dispatch('toggleCancelEventCreation')
    await router.push({ name: 'EventsPage' })
  }
}

function saveAsDraftEvent() {
  emit('saveAsDraft')
  store.dispatch('toggleCancelEventCreation')
}
</script>
<style lang="scss" scoped>
.cancel {
  &__title {
    text-transform: uppercase;
  }
}
</style>
